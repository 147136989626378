import { ServerFetch } from '../main-model'

export default class BillingNoteCustomerModel extends ServerFetch {
    generateBillingNoteCustomerLastCode = (data) => this.authFetch({
        url: 'billing-note-customer/generateBillingNoteCustomerLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getBillingNoteCustomerBy = (data) => this.authFetch({
        url: 'billing-note-customer/getBillingNoteCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getBillingNoteCustomerByCode = (data) => this.authFetch({
        url: 'billing-note-customer/getBillingNoteCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseCustomerBillingNoteCustomer = (data) => this.authFetch({
        url: 'billing-note-customer/getAdviseCustomerBillingNoteCustomer',
        method: 'POST',
        body: JSON.stringify(data),
    })

    increaseBillingNoteCustomerPrintByCode = (data) => this.authFetch({
        url: 'billing-note-customer/increaseBillingNoteCustomerPrintByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateBillingNoteCustomerBy = (data) => this.authFetch({
        url: 'billing-note-customer/updateBillingNoteCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertBillingNoteCustomer = (data) => this.authFetch({
        url: 'billing-note-customer/insertBillingNoteCustomer',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteBillingNoteCustomerByCode = (data) => this.authFetch({
        url: 'billing-note-customer/deleteBillingNoteCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}