import { ServerFetch } from '../main-model'

export default class CustomerCarModel extends ServerFetch {
    getCustomerCarByCustomerCode = (data) => this.authFetch({
        url: 'customer-car/getCustomerCarByCustomerCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertCustomerCar = (data) => this.authFetch({
        url: 'customer-car/insertCustomerCar',
        method: 'POST',
        body: JSON.stringify(data),
    })
    deleteCustomerCarByCustomerCode = (data) => this.authFetch({
        url: 'customer-car/deleteCustomerCarByCustomerCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}