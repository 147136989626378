import { ServerFetch } from '../main-model'

export default class PreOrderModel extends ServerFetch {
    getPreOrderBy = (data) => this.authFetch({
        url: 'pre-order/getPreOrderBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPreOrderByCode = (data) => this.authFetch({
        url: 'pre-order/getPreOrderByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertPreOrder = (data) => this.authFetch({
        url: 'pre-order/insertPreOrder',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updatePreOrderByCode = (data) => this.authFetch({
        url: 'pre-order/updatePreOrderByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}