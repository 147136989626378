import { ServerFetch } from '../main-model'

export default class TaxReportModel extends ServerFetch {
    getPurchaseTaxReportBy = (data) => this.authFetch({
        url: 'tax-report/getPurchaseTaxReportBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSaleTaxReportBy = (data) => this.authFetch({
        url: 'tax-report/getSaleTaxReportBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}