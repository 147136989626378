import { ServerFetch } from '../main-model'

export default class DeliveryNoteDiscountListModel extends ServerFetch {
    getDeliveryNoteDiscountListBy = (data) => this.authFetch({
        url: 'delivery-note-discount-list/getDeliveryNoteDiscountListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateDeliveryNoteDiscountListBy = (data) => this.authFetch({
        url: 'delivery-note-discount-list/generateDeliveryNoteDiscountListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}