import { ServerFetch } from '../main-model'

export default class ProductBrandModel extends ServerFetch {
    async getProductBrandLastCode(data) {
        return this.authFetch({
            url: 'product-brand/getProductBrandLastCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getProductBrandByCode(data) {
        return this.authFetch({
            url: 'product-brand/getProductBrandByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getProductBrandBy(data) {
        return this.authFetch({
            url: 'product-brand/getProductBrandBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async insertProductBrand(data) {
        return this.authFetch({
            url: 'product-brand/insertProductBrand',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async updateProductBrandByCode(data) {
        return this.authFetch({
            url: 'product-brand/updateProductBrandByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async deleteProductBrandByCode(data) {
        return this.authFetch({
            url: 'product-brand/deleteProductBrandByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
}