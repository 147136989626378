import { ServerFetch } from '../main-model'

export default class InvoiceSupplierModel extends ServerFetch {
    generateInvoiceSupplierLastCode = (data) => this.authFetch({
        url: 'invoice-supplier/generateInvoiceSupplierLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceSupplierBy = (data) => this.authFetch({
        url: 'invoice-supplier/getInvoiceSupplierBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceSupplierByCode = (data) => this.authFetch({
        url: 'invoice-supplier/getInvoiceSupplierByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseSupplierInvoiceSupplier = (data) => this.authFetch({
        url: 'invoice-supplier/getAdviseSupplierInvoiceSupplier',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdvisePurchaseOrderInvoiceSupplier = (data) => this.authFetch({
        url: 'invoice-supplier/getAdvisePurchaseOrderInvoiceSupplier',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateInvoiceSupplierBy = (data) => this.authFetch({
        url: 'invoice-supplier/updateInvoiceSupplierBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertInvoiceSupplier = (data) => this.authFetch({
        url: 'invoice-supplier/insertInvoiceSupplier',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteInvoiceSupplierByCode = (data) => this.authFetch({
        url: 'invoice-supplier/deleteInvoiceSupplierByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}