import { ServerFetch } from '../main-model'

export default class ChequeReceiveModel extends ServerFetch {
    generateChequeReceiveLastCode = (data) => this.authFetch({
        url: 'cheque-receive/generateChequeReceiveLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getChequeReceiveBy = (data) => this.authFetch({
        url: 'cheque-receive/getChequeReceiveBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getChequeReceiveByCode = (data) => this.authFetch({
        url: 'cheque-receive/getChequeReceiveByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateChequeReceiveBy = (data) => this.authFetch({
        url: 'cheque-receive/generateChequeReceiveBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    checkChequeReceiveNumberBy = (data) => this.authFetch({
        url: 'cheque-receive/checkChequeReceiveNumberBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateNotPassChequeReceiveByCode = (data) => this.authFetch({
        url: 'cheque-receive/updateNotPassChequeReceiveByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateUnPassChequeReceiveByCode = (data) => this.authFetch({
        url: 'cheque-receive/updateUnPassChequeReceiveByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updatePassChequeReceiveBy = (data) => this.authFetch({
        url: 'cheque-receive/updatePassChequeReceiveBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateChequeReceiveBy = (data) => this.authFetch({
        url: 'cheque-receive/updateChequeReceiveBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertChequeReceive = (data) => this.authFetch({
        url: 'cheque-receive/insertChequeReceive',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteChequeReceiveByCode = (data) => this.authFetch({
        url: 'cheque-receive/deleteChequeReceiveByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}