import { ServerFetch } from '../main-model'

export default class SaleDepositListModel extends ServerFetch {
    getSaleDepositListBy = (data) => this.authFetch({
        url: 'sale-deposit-list/getSaleDepositListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateSaleDepositListBy = (data) => this.authFetch({
        url: 'sale-deposit-list/generateSaleDepositListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}