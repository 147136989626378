import { ServerFetch } from '../main-model'

export default class PromotionModel extends ServerFetch {
    getPromotionLastCode = (data) => this.authFetch({
        url: 'promotion/getPromotionLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPromotionBy = (data) => this.authFetch({
        url: 'promotion/getPromotionBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPromotionByCode = (data) => this.authFetch({
        url: 'promotion/getPromotionByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updatePromotionBy = (data) => this.authFetch({
        url: 'promotion/updatePromotionBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertPromotion = (data) => this.authFetch({
        url: 'promotion/insertPromotion',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deletePromotionByCode = (data) => this.authFetch({
        url: 'promotion/deletePromotionByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}