import { ServerFetch } from '../main-model'

export default class CarGenerationModel extends ServerFetch {
    async getCarGenerationLastCode(data) {
        return this.authFetch({
            url: 'car-generation/getCarGenerationLastCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getCarGenerationBy(data) {
        return this.authFetch({
            url: 'car-generation/getCarGenerationBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getCarGenerationByCode(data) {
        return this.authFetch({
            url: 'car-generation/getCarGenerationByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async insertCarGeneration(data) {
        return this.authFetch({
            url: 'car-generation/insertCarGeneration',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async updateCarGenerationBy(data) {
        return this.authFetch({
            url: 'car-generation/updateCarGenerationBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async deleteCarGenerationByCode(data) {
        return this.authFetch({
            url: 'car-generation/deleteCarGenerationByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
}