import { ServerFetch } from '../main-model'

export default class ReceiptListModel extends ServerFetch {
    getReceiptBy = (data) => this.authFetch({
        url: 'receipt/getReceiptBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptByDelivery = (data) => this.authFetch({
        url: 'receipt/getReceiptByDelivery',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptByCode = (data) => this.authFetch({
        url: 'receipt/getReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateReceiptBy = (data) => this.authFetch({
        url: 'receipt/generateReceiptBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateDeliveryStatus = (data) => this.authFetch({
        url: 'receipt/updateDeliveryStatus',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateReceipt = (data) => this.authFetch({
        url: 'receipt/updateReceipt',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    deleteReceiptByCode = (data) => this.authFetch({
        url: 'receipt/deleteReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}