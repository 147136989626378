import { ServerFetch } from '../main-model'

export default class SaleDepositModel extends ServerFetch {
    generateSaleDepositLastCode = (data) => this.authFetch({
        url: 'sale-deposit/generateSaleDepositLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSaleDepositBy = (data) => this.authFetch({
        url: 'sale-deposit/getSaleDepositBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSaleDepositByCode = (data) => this.authFetch({
        url: 'sale-deposit/getSaleDepositByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateInvoiceSaleDepositBy = (data) => this.authFetch({
        url: 'sale-deposit/generateInvoiceSaleDepositBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateSaleDepositBy = (data) => this.authFetch({
        url: 'sale-deposit/updateSaleDepositBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertSaleDeposit = (data) => this.authFetch({
        url: 'sale-deposit/insertSaleDeposit',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteSaleDepositByCode = (data) => this.authFetch({
        url: 'sale-deposit/deleteSaleDepositByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}