import { ServerFetch } from '../main-model'

export default class BillingNoteSupplierModel extends ServerFetch {
    generateBillingNoteSupplierLastCode = (data) => this.authFetch({
        url: 'billing-note-supplier/generateBillingNoteSupplierLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getBillingNoteSupplierBy = (data) => this.authFetch({
        url: 'billing-note-supplier/getBillingNoteSupplierBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getBillingNoteSupplierByCode = (data) => this.authFetch({
        url: 'billing-note-supplier/getBillingNoteSupplierByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseSupplierBillingNoteSupplier = (data) => this.authFetch({
        url: 'billing-note-supplier/getAdviseSupplierBillingNoteSupplier',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateBillingNoteSupplierBy = (data) => this.authFetch({
        url: 'billing-note-supplier/updateBillingNoteSupplierBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertBillingNoteSupplier = (data) => this.authFetch({
        url: 'billing-note-supplier/insertBillingNoteSupplier',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteBillingNoteSupplierByCode = (data) => this.authFetch({
        url: 'billing-note-supplier/deleteBillingNoteSupplierByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}