import { ServerFetch } from '../main-model'

export default class DebitNoteSupplierModel extends ServerFetch {
    generateDebitNoteSupplierLastCode = (data) => this.authFetch({
        url: 'debit-note-supplier/generateDebitNoteSupplierLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitNoteSupplierBy = (data) => this.authFetch({
        url: 'debit-note-supplier/getDebitNoteSupplierBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitNoteSupplierByCode = (data) => this.authFetch({
        url: 'debit-note-supplier/getDebitNoteSupplierByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateDebitNoteSupplierBy = (data) => this.authFetch({
        url: 'debit-note-supplier/updateDebitNoteSupplierBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertDebitNoteSupplier = (data) => this.authFetch({
        url: 'debit-note-supplier/insertDebitNoteSupplier',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteDebitNoteSupplierByCode = (data) => this.authFetch({
        url: 'debit-note-supplier/deleteDebitNoteSupplierByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}