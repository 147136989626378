import { ServerFetch } from '../main-model'

export default class QuotationModel extends ServerFetch {
    generateQuotationLastCode = (data) => this.authFetch({
        url: 'quotation/generateQuotationLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getQuotationBy = (data) => this.authFetch({
        url: 'quotation/getQuotationBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertQuotation = (data) => this.authFetch({
        url: 'quotation/insertQuotation',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getQuotationByCode = (data) => this.authFetch({
        url: 'quotation/getQuotationByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateQuotationBy = (data) => this.authFetch({
        url: 'quotation/updateQuotationBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteQuotationByCode = (data) => this.authFetch({
        url: 'quotation/deleteQuotationByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}
