import { ServerFetch } from '../main-model'

export default class ReturnProductModel extends ServerFetch {
    getReturnProductLastCode = (data) => this.authFetch({
        url: 'return-product/getReturnProductLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReturnProductBy = (data) => this.authFetch({
        url: 'return-product/getReturnProductBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReturnProductByCode = (data) => this.authFetch({
        url: 'return-product/getReturnProductByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateReturnProductBy = (data) => this.authFetch({
        url: 'return-product/updateReturnProductBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertReturnProduct = (data) => this.authFetch({
        url: 'return-product/insertReturnProduct',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteReturnProductByCode = (data) => this.authFetch({
        url: 'return-product/deleteReturnProductByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}