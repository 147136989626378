import { ServerFetch } from '../main-model'

export default class ReportDebtorModel extends ServerFetch {
    async getReportDebtorBy(data) {
        return this.authFetch({
            url: 'report-debtor/getReportDebtorBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getReportSaleOrderBy(data) {
        return this.authFetch({
            url: 'report-debtor/getReportSaleOrderBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getReportBillingNoteCustomerBy(data) {
        return this.authFetch({
            url: 'report-debtor/getReportBillingNoteCustomerBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getReportInvoiceCustomerBy(data) {
        return this.authFetch({
            url: 'report-debtor/getReportInvoiceCustomerBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getReportCreditNoteCustomerBy(data) {
        return this.authFetch({
            url: 'report-debtor/getReportDebitNoteCustomerBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getReportDebitNoteCustomerBy(data) {
        return this.authFetch({
            url: 'report-debtor/getReportDebitNoteCustomerBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getReportDebtorInvoiceBy(data) {
        return this.authFetch({
            url: 'report-debtor/getReportDebtorInvoiceBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getReportDebtorPayableBy(data) {
        return this.authFetch({
            url: 'report-debtor/getReportDebtorPayableBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getReportFinanceDebitBy(data) {
        return this.authFetch({
            url: 'report-debtor/getReportFinanceDebitBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
}