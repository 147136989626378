import { ServerFetch } from '../main-model'

export default class ProductModel extends ServerFetch {
    /*   async getProductSpecLastCode(data) {
          return this.authFetch({
              url: 'product-spec/getProductSpecLastCode',
              method: 'POST',
              body: JSON.stringify(data),
          })
      } */

    async getProductSpecBy(data) {
        return this.authFetch({
            url: 'product-spec/getProductSpecBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
    async getProductSpecByCategoryCode(data) {
        return this.authFetch({
            url: 'product-spec/getProductSpecByCategoryCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
}