import { ServerFetch } from '../main-model'

export default class StockSettingModel extends ServerFetch {
    generateStockSettingLastCode = (data) => this.authFetch({
        url: 'stock-setting/generateStockSettingLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockSettingBy = (data) => this.authFetch({
        url: 'stock-setting/getStockSettingBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockSettingByCode = (data) => this.authFetch({
        url: 'stock-setting/getStockSettingByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateStockSettingBy = (data) => this.authFetch({
        url: 'stock-setting/updateStockSettingBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    createStock = (data) => this.authFetch({
        url: 'stock-setting/createStock',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertStockSetting = (data) => this.authFetch({
        url: 'stock-setting/insertStockSetting',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteStockSettingByCode = (data) => this.authFetch({
        url: 'stock-setting/deleteStockSettingByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}