import { ServerFetch } from '../main-model'

export default class CreditNoteCustomerModel extends ServerFetch {
    getCreditNoteCustomerLastCode = (data) => this.authFetch({
        url: 'credit-note-customer/getCreditNoteCustomerLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCreditNoteCustomerBy = (data) => this.authFetch({
        url: 'credit-note-customer/getCreditNoteCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCreditNoteCustomerByCode = (data) => this.authFetch({
        url: 'credit-note-customer/getCreditNoteCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    cancelCreditNoteCustomerByCode = (data) => this.authFetch({
        url: 'credit-note-customer/cancelCreditNoteCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    unCancelCreditNoteCustomerByCode = (data) => this.authFetch({
        url: 'credit-note-customer/unCancelCreditNoteCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateCreditNoteCustomerBy = (data) => this.authFetch({
        url: 'credit-note-customer/updateCreditNoteCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertCreditNoteCustomer = (data) => this.authFetch({
        url: 'credit-note-customer/insertCreditNoteCustomer',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteCreditNoteCustomerByCode = (data) => this.authFetch({
        url: 'credit-note-customer/deleteCreditNoteCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}