import { ServerFetch } from '../main-model'

export default class CreditorReportModel extends ServerFetch {
    getInvoiceSupplierReportBy = (data) => this.authFetch({
        url: 'creditor-report/getInvoiceSupplierReportBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceSupplierFullReportBy = (data) => this.authFetch({
        url: 'creditor-report/getInvoiceSupplierFullReportBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPurchaseOrderReportBy = (data) => this.authFetch({
        url: 'creditor-report/getPurchaseOrderReportBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSupplierListReportBy = (data) => this.authFetch({
        url: 'creditor-report/getSupplierListReportBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getFinanceCreditReportBy = (data) => this.authFetch({
        url: 'creditor-report/getFinanceCreditReportBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getFinanceCreditReportFullBy = (data) => this.authFetch({
        url: 'creditor-report/getFinanceCreditReportFullBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCreditorListReportBy = (data) => this.authFetch({
        url: 'creditor-report/getCreditorListReportBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getBeforeCreditReportBy = (data) => this.authFetch({
        url: 'creditor-report/getBeforeCreditReportBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceCreditReportBy = (data) => this.authFetch({
        url: 'creditor-report/getInvoiceCreditReportBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCreditDebitReportBy = (data) => this.authFetch({
        url: 'creditor-report/getCreditDebitReportBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCreditCreditReportBy = (data) => this.authFetch({
        url: 'creditor-report/getCreditCreditReportBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPaymentCreditReportBy = (data) => this.authFetch({
        url: 'creditor-report/getPaymentCreditReportBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCreditorListDetailReportBy = (data) => this.authFetch({
        url: 'creditor-report/getCreditorListDetailReportBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAccountsPayableListReportBy = (data) => this.authFetch({
        url: 'creditor-report/getAccountsPayableListReportBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}