import { ServerFetch } from '../main-model'

export default class InvoiceCustomerModel extends ServerFetch {
    generateInvoiceCustomerLastCode = (data) => this.authFetch({
        url: 'invoice-customer/generateInvoiceCustomerLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceCustomerBy = (data) => this.authFetch({
        url: 'invoice-customer/getInvoiceCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceCustomerByCode = (data) => this.authFetch({
        url: 'invoice-customer/getInvoiceCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseReceiptInvoiceCustomer = (data) => this.authFetch({
        url: 'invoice-customer/getAdviseReceiptInvoiceCustomer',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseCustomerInvoiceCustomer = (data) => this.authFetch({
        url: 'invoice-customer/getAdviseCustomerInvoiceCustomer',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceSaleOrderByCustomer = (data) => this.authFetch({
        url: 'invoice-customer/getInvoiceSaleOrderByCustomer',
        method: 'POST',
        body: JSON.stringify(data),
    })

    cancelInvoiceCustomerByCode = (data) => this.authFetch({
        url: 'invoice-customer/cancelInvoiceCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    unCancelInvoiceCustomerByCode = (data) => this.authFetch({
        url: 'invoice-customer/unCancelInvoiceCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    increaseInvoiceCustomerPrintByCode = (data) => this.authFetch({
        url: 'invoice-customer/increaseInvoiceCustomerPrintByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateInvoiceCustomerBy = (data) => this.authFetch({
        url: 'invoice-customer/updateInvoiceCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertInvoiceCustomer = (data) => this.authFetch({
        url: 'invoice-customer/insertInvoiceCustomer',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteInvoiceCustomerByCode = (data) => this.authFetch({
        url: 'invoice-customer/deleteInvoiceCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}