import { ServerFetch } from '../main-model'

export default class AssetGroupModel extends ServerFetch {
    async getLastCodeBy(data) {
        return this.authFetch({
            url: 'generate-format/get-last-code-by',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
    async getAssetGroupBy(data) {
        return this.authFetch({
            url: 'asset-group/getAssetGroupBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getAssetGroupByCode(data) {
        return this.authFetch({
            url: 'asset-group/getAssetGroupByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async updateAssetGroupBy(data) {
        return this.authFetch({
            url: 'asset-group/updateAssetGroup',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async insertAssetGroup(data) {
        return this.authFetch({
            url: 'asset-group/insertAssetGroup',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async deleteAssetGroupByCode(data) {
        return this.authFetch({
            url: 'asset-group/deleteAssetGroup',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
    
}