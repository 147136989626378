import { ServerFetch } from '../main-model'

export default class BillingNoteSupplierListModel extends ServerFetch {
    getBillingNoteSupplierListBy = (data) => this.authFetch({
        url: 'billing-note-supplier-list/getBillingNoteSupplierListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateBillingNoteSupplierListBy = (data) => this.authFetch({
        url: 'billing-note-supplier-list/generateBillingNoteSupplierListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}