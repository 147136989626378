import { ServerFetch } from '../main-model'

export default class DeliveryListModel extends ServerFetch {
    getDeliveryBy = (data) => this.authFetch({
        url: 'delivery/getDeliveryBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDeliveryByCode = (data) => this.authFetch({
        url: 'delivery/getDeliveryByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateDeliveryLastCode = (data) => this.authFetch({
        url: 'delivery/generateDeliveryLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertDelivery = (data) => this.authFetch({
        url: 'delivery/insertDelivery',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateDeliveryBy = (data) => this.authFetch({
        url: 'delivery/updateDeliveryBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteDeliveryByCode = (data) => this.authFetch({
        url: 'delivery/deleteDeliveryByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}