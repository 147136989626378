import { ServerFetch } from '../main-model'

export default class ReceiptListModel extends ServerFetch {
    generateSaleReceiptLastCode = (data) => this.authFetch({
        url: 'sale-receipt/generateSaleReceiptLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSaleReceiptBy = (data) => this.authFetch({
        url: 'sale-receipt/getSaleReceiptBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSaleReceiptByCode = (data) => this.authFetch({
        url: 'sale-receipt/getSaleReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateSaleReceipt = (data) => this.authFetch({
        url: 'sale-receipt/updateSaleReceipt',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertSaleReceipt = (data) => this.authFetch({
        url: 'sale-receipt/insertSaleReceipt',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteSaleReceiptByCode = (data) => this.authFetch({
        url: 'sale-receipt/deleteSaleReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}