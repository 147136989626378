import { ServerFetch } from '../main-model'

export default class ProductSubImageModel extends ServerFetch {
    async getProductSubImageByProductCode(data) {
        return this.authFetch({
            url: 'product-sub-image/getProductSubImageByProductCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
}