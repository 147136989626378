import { ServerFetch } from '../main-model'

export default class PurchaseDepositModel extends ServerFetch {
    generatePurchaseDepositLastCode = (data) => this.authFetch({
        url: 'purchase-deposit/generatePurchaseDepositLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPurchaseDepositBy = (data) => this.authFetch({
        url: 'purchase-deposit/getPurchaseDepositBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPurchaseDepositByCode = (data) => this.authFetch({
        url: 'purchase-deposit/getPurchaseDepositByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateInvoicePurchaseDepositBy = (data) => this.authFetch({
        url: 'purchase-deposit/generateInvoicePurchaseDepositBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updatePurchaseDepositBy = (data) => this.authFetch({
        url: 'purchase-deposit/updatePurchaseDepositBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertPurchaseDeposit = (data) => this.authFetch({
        url: 'purchase-deposit/insertPurchaseDeposit',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deletePurchaseDepositByCode = (data) => this.authFetch({
        url: 'purchase-deposit/deletePurchaseDepositByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}