import { ServerFetch } from '../main-model'

export default class PurchaseRequestModel extends ServerFetch {
    generatePurchaseRequestLastCode = (data) => this.authFetch({
        url: "purchase-request/generatePurchaseRequestLastCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    getPurchaseRequestBy = (data) => this.authFetch({
        url: "purchase-request/getPurchaseRequestBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    getPurchaseRequestByCode = (data) => this.authFetch({
        url: "purchase-request/getPurchaseRequestByCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    getPurchaseRequestOrderByCode = (data) => this.authFetch({
        url: "purchase-request/getPurchaseRequestOrderByCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    approvePurchaseRequestBy = (data) => this.authFetch({
        url: "purchase-request/approvePurchaseRequestBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    cancelPurchaseRequestByCode = (data) => this.authFetch({
        url: "purchase-request/cancelPurchaseRequestByCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    unCancelPurchaseRequestByCode = (data) => this.authFetch({
        url: "purchase-request/unCancelPurchaseRequestByCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    updatePurchaseRequestBy = (data) => this.authFetch({
        url: "purchase-request/updatePurchaseRequestBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    insertPurchaseRequest = (data) => this.authFetch({
        url: "purchase-request/insertPurchaseRequest",
        method: "POST",
        body: JSON.stringify(data),
    })

    deletePurchaseRequestByCode = (data) => this.authFetch({
        url: "purchase-request/deletePurchaseRequestByCode",
        method: "POST",
        body: JSON.stringify(data),
    })
}