import { ServerFetch } from '../main-model'

export default class PurchaseOrderListModel extends ServerFetch {
    getPurchaseOrderListBy = (data) => this.authFetch({
        url: 'purchase-order-list/getPurchaseOrderListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generatePurchaseOrderListBy = (data) => this.authFetch({
        url: 'purchase-order-list/generatePurchaseOrderListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updatePurchaseOrderListCloseBy = (data) => this.authFetch({
        url: 'purchase-order-list/updatePurchaseOrderListCloseBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}