import { ServerFetch } from '../main-model'

export default class AssetModel extends ServerFetch {
    
    async getLastCodeBy(data) {
        return this.authFetch({
            url: 'generate-format/get-last-code-by',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
    async getAssetBy(data) {
        return this.authFetch({
            url: 'asset/getAssetBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getAssetByCode(data) {
        return this.authFetch({
            url: 'asset/getAssetByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async updateAssetBy(data) {
        return this.authFetch({
            url: 'asset/updateAsset',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async insertAsset(data) {
        return this.authFetch({
            url: 'asset/insertAsset',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async deleteAssetByCode(data) {
        return this.authFetch({
            url: 'asset/deleteAsset',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
    
}