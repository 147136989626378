import { ServerFetch } from '../main-model'

export default class SaleReceiptListModel extends ServerFetch {
    getSaleReceiptListBy = (data) => this.authFetch({
        url: 'sale-receipt-list/getSaleReceiptListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertSaleReceiptListBy = (data) => this.authFetch({
        url: 'sale-receipt-list/insertSaleReceiptListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateSaleReceiptListBy = (data) => this.authFetch({
        url: 'sale-receipt-list/updateSaleReceiptListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteSaleReceiptListByCode = (data) => this.authFetch({
        url: 'sale-receipt-list/deleteReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}