import { ServerFetch } from '../main-model'

export default class DebitNoteCustomerModel extends ServerFetch {
    getDebitNoteCustomerLastCode = (data) => this.authFetch({
        url: 'debit-note-customer/getDebitNoteCustomerLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitNoteCustomerBy = (data) => this.authFetch({
        url: 'debit-note-customer/getDebitNoteCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitNoteCustomerByCode = (data) => this.authFetch({
        url: 'debit-note-customer/getDebitNoteCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    cancelDebitNoteCustomerByCode = (data) => this.authFetch({
        url: 'debit-note-customer/cancelDebitNoteCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    unCancelDebitNoteCustomerByCode = (data) => this.authFetch({
        url: 'debit-note-customer/unCancelDebitNoteCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateDebitNoteCustomerBy = (data) => this.authFetch({
        url: 'debit-note-customer/updateDebitNoteCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertDebitNoteCustomer = (data) => this.authFetch({
        url: 'debit-note-customer/insertDebitNoteCustomer',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteDebitNoteCustomerByCode = (data) => this.authFetch({
        url: 'debit-note-customer/deleteDebitNoteCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}