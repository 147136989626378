import { ServerFetch } from '../main-model'

export default class StockMoveModel extends ServerFetch {
    generateStockMoveLastCode = (data) => this.authFetch({
        url: 'stock-move/generateStockMoveLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockMoveBy = (data) => this.authFetch({
        url: 'stock-move/getStockMoveBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockMoveByCode = (data) => this.authFetch({
        url: 'stock-move/getStockMoveByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseStockGroupMove = (data) => this.authFetch({
        url: 'stock-move/getAdviseStockGroupMove',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseStockRequestMove = (data) => this.authFetch({
        url: 'stock-move/getAdviseStockRequestMove',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateStockMoveBy = (data) => this.authFetch({
        url: 'stock-move/updateStockMoveBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertStockMove = (data) => this.authFetch({
        url: 'stock-move/insertStockMove',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteStockMoveByCode = (data) => this.authFetch({
        url: 'stock-move/deleteStockMoveByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}