import { ServerFetch } from '../main-model'

export default class CompanyInfoModel extends ServerFetch {
    getCompanyInfo = (data) => this.authFetch({
        url: 'company-info/getCompanyInfo',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateCompanyInfoBy = (data) => this.authFetch({
        url: 'company-info/updateCompanyInfoBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}