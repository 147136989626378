import { ServerFetch } from '../main-model'

export default class StockUnpackListModel extends ServerFetch {
    getStockUnpackListBy = (data) => this.authFetch({
        url: 'stock-unpack-list/getStockUnpackListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateStockUnpackListBy = (data) => this.authFetch({
        url: 'stock-unpack-list/generateStockUnpackListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}