import { ServerFetch } from '../main-model'

export default class StockMoveListModel extends ServerFetch {
    getStockMoveListBy = (data) => this.authFetch({
        url: 'stock-move-list/getStockMoveListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateStockMoveListBy = (data) => this.authFetch({
        url: 'stock-move-list/generateStockMoveListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}