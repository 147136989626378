import { ServerFetch } from '../main-model'

export default class BookBankModel extends ServerFetch {
    generateBookBankLastCode = (data) => this.authFetch({
        url: 'book-bank/generateBookBankLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getBookBankBy = (data) => this.authFetch({
        url: 'book-bank/getBookBankBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getBookBankByCode = (data) => this.authFetch({
        url: 'book-bank/getBookBankByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateBookBankBy = (data) => this.authFetch({
        url: 'book-bank/updateBookBankBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertBookBank = (data) => this.authFetch({
        url: 'book-bank/insertBookBank',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteBookBankByCode = (data) => this.authFetch({
        url: 'book-bank/deleteBookBankByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}