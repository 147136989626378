import { ServerFetch } from '../main-model'

export default class SaleCutOffModel extends ServerFetch {
    getSaleCutOffBy = (data) => this.authFetch({
        url: 'sale-cut-off/getSaleCutOffBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSaleCutOffByCode = (data) => this.authFetch({
        url: 'sale-cut-off/getSaleCutOffByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseReceiveSaleCutOff = (data) => this.authFetch({
        url: 'sale-cut-off/getAdviseReceiveSaleCutOff',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateReceiveSaleCutOffByCode = (data) => this.authFetch({
        url: 'sale-cut-off/updateReceiveSaleCutOffByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}