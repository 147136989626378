import { ServerFetch } from '../main-model'

export default class ProductGenerationModel extends ServerFetch {
    async getProductGenerationLastCode(data) {
        return this.authFetch({
            url: 'product-generation/getProductGenerationLastCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getProductGenerationBy(data) {
        return this.authFetch({
            url: 'product-generation/getProductGenerationBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getProductGenerationByCode(data) {
        return this.authFetch({
            url: 'product-generation/getProductGenerationByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async insertProductGeneration(data) {
        return this.authFetch({
            url: 'product-generation/insertProductGeneration',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async updateProductGenerationByCode(data) {
        return this.authFetch({
            url: 'product-generation/updateProductGenerationByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async deleteProductGenerationByCode(data) {
        return this.authFetch({
            url: 'product-generation/deleteProductGenerationByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
}