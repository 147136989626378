import { ServerFetch } from '../main-model'

export default class JournalModel extends ServerFetch {
    generateJournalLastCode = (data) => this.authFetch({
        url: 'journal/generateJournalLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getJournalBy = (data) => this.authFetch({
        url: 'journal/getJournalBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getJournalByCode = (data) => this.authFetch({
        url: 'journal/getJournalByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateJournalBy = (data) => this.authFetch({
        url: 'journal/updateJournalBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertJournal = (data) => this.authFetch({
        url: 'journal/insertJournal',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteJournalByCode = (data) => this.authFetch({
        url: 'journal/deleteJournalByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    recordJournalBy = (data) => this.authFetch({
        url: 'journal/recordJournalBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}    