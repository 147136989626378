import { ServerFetch } from '../main-model'

export default class FinanceCreditModel extends ServerFetch {
    generateFinanceCreditLastCode = (data) => this.authFetch({
        url: 'finance-credit/generateFinanceCreditLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getFinanceCreditBy = (data) => this.authFetch({
        url: 'finance-credit/getFinanceCreditBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getFinanceCreditByCode = (data) => this.authFetch({
        url: 'finance-credit/getFinanceCreditByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseSupplierFinanceCredit = (data) => this.authFetch({
        url: 'finance-credit/getAdviseSupplierFinanceCredit',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateFinanceCreditBy = (data) => this.authFetch({
        url: 'finance-credit/updateFinanceCreditBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertFinanceCredit = (data) => this.authFetch({
        url: 'finance-credit/insertFinanceCredit',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteFinanceCreditByCode = (data) => this.authFetch({
        url: 'finance-credit/deleteFinanceCreditByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}