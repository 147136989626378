import { ServerFetch } from '../main-model'

export default class FinanceCreditListModel extends ServerFetch {
    getFinanceCreditListBy = (data) => this.authFetch({
        url: 'finance-credit-list/getFinanceCreditListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateFinanceCreditListBy = (data) => this.authFetch({
        url: 'finance-credit-list/generateFinanceCreditListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}