import { ServerFetch } from '../main-model'

export default class StockRequestModel extends ServerFetch {
    generateStockRequestLastCode = (data) => this.authFetch({
        url: "stock-request/generateStockRequestLastCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    getStockRequestBy = (data) => this.authFetch({
        url: "stock-request/getStockRequestBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    getStockRequestByCode = (data) => this.authFetch({
        url: "stock-request/getStockRequestByCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    updateStockRequestBy = (data) => this.authFetch({
        url: "stock-request/updateStockRequestBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    insertStockRequest = (data) => this.authFetch({
        url: "stock-request/insertStockRequest",
        method: "POST",
        body: JSON.stringify(data),
    })

    deleteStockRequestByCode = (data) => this.authFetch({
        url: "stock-request/deleteStockRequestByCode",
        method: "POST",
        body: JSON.stringify(data),
    })
}
