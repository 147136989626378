import { ServerFetch } from '../main-model'

export default class ProductInitModel extends ServerFetch {
    getProductInitBy = (data) => this.authFetch({
        url: 'product-init/getProductInitBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductInStockCardBy = (data) => this.authFetch({
        url: 'product-init/getProductInStockCardBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    updateProductInitBy = (data) => this.authFetch({
        url: 'product-init/updateProductInitBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    insertProductInit = (data) => this.authFetch({
        url: 'product-init/insertProductInit',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    deleteProductInitByCode = (data) => this.authFetch({
        url: 'product-init/deleteProductInitByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}