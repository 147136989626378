import { ServerFetch } from '../main-model'

export default class ReceiptTemporaryModel extends ServerFetch {
    generateReceiptTemporaryLastCode = (data) => this.authFetch({
        url: 'receipt-temporary/generateReceiptTemporaryLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateReceiptTemporaryVatLastCode = (data) => this.authFetch({
        url: 'receipt-temporary/generateReceiptTemporaryVatLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptTemporaryBy = (data) => this.authFetch({
        url: 'receipt-temporary/getReceiptTemporaryBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptTemporaryByCode = (data) => this.authFetch({
        url: 'receipt-temporary/getReceiptTemporaryByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptTemporaryOnPayment = (data) => this.authFetch({
        url: 'receipt-temporary/getReceiptTemporaryOnPayment',
        method: 'POST',
        body: JSON.stringify(data),
    })

    requestCancelReceiptTemporaryByCode = (data) => this.authFetch({
        url: 'receipt-temporary/requestCancelReceiptTemporaryByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    increaseReceiptTemporaryPrintByCode = (data) => this.authFetch({
        url: 'receipt-temporary/increaseReceiptTemporaryPrintByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateReceiptTemporaryInPosBy = (data) => this.authFetch({
        url: 'receipt-temporary/updateReceiptTemporaryInPosBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertReceiptTemporary = (data) => this.authFetch({
        url: 'receipt-temporary/insertReceiptTemporary',
        method: 'POST',
        body: JSON.stringify(data),
    })
}