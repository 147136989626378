import { ServerFetch } from '../main-model'

export default class InvoiceCustomerTempModel extends ServerFetch {
    generateInvoiceCustomerTempLastCode = (data) => this.authFetch({
        url: 'invoice-customer-temp/generateInvoiceCustomerTempLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceCustomerTempBy = (data) => this.authFetch({
        url: 'invoice-customer-temp/getInvoiceCustomerTempBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceCustomerTempByCode = (data) => this.authFetch({
        url: 'invoice-customer-temp/getInvoiceCustomerTempByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseReceiptInvoiceCustomerTemp = (data) => this.authFetch({
        url: 'invoice-customer-temp/getAdviseReceiptInvoiceCustomerTemp',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseCustomerInvoiceCustomerTemp = (data) => this.authFetch({
        url: 'invoice-customer-temp/getAdviseCustomerInvoiceCustomerTemp',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceSaleOrderByCustomer = (data) => this.authFetch({
        url: 'invoice-customer-temp/getInvoiceSaleOrderByCustomer',
        method: 'POST',
        body: JSON.stringify(data),
    })

    cancelInvoiceCustomerTempByCode = (data) => this.authFetch({
        url: 'invoice-customer-temp/cancelInvoiceCustomerTempByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    unCancelInvoiceCustomerTempByCode = (data) => this.authFetch({
        url: 'invoice-customer-temp/unCancelInvoiceCustomerTempByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateInvoiceCustomerTempBy = (data) => this.authFetch({
        url: 'invoice-customer-temp/updateInvoiceCustomerTempBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertInvoiceCustomerTemp = (data) => this.authFetch({
        url: 'invoice-customer-temp/insertInvoiceCustomerTemp',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteInvoiceCustomerTempByCode = (data) => this.authFetch({
        url: 'invoice-customer-temp/deleteInvoiceCustomerTempByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}