import { ServerFetch } from '../main-model'

export default class DebitMethodModel extends ServerFetch {
    generateDebitMethodLastCode = (data) => this.authFetch({
        url: 'debit-method/generateDebitMethodLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitMethodBy = (data) => this.authFetch({
        url: 'debit-method/getDebitMethodBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitMethodByCode = (data) => this.authFetch({
        url: 'debit-method/getDebitMethodByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    showDebitMethodByCode = (data) => this.authFetch({
        url: 'debit-method/showDebitMethodByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    hideDebitMethodByCode = (data) => this.authFetch({
        url: 'debit-method/hideDebitMethodByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateDebitMethodBy = (data) => this.authFetch({
        url: 'debit-method/updateDebitMethodBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertDebitMethod = (data) => this.authFetch({
        url: 'debit-method/insertDebitMethod',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteDebitMethodByCode = (data) => this.authFetch({
        url: 'debit-method/deleteDebitMethodByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}