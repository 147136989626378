import { ServerFetch } from '../main-model'

export default class SaleOfferListModel extends ServerFetch {
    getSaleOfferListBy = (data) => this.authFetch({
        url: 'sale-offer-list/getSaleOfferListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateSaleOfferListProductNoBy = (data) => this.authFetch({
        url: 'sale-offer-list/updateSaleOfferListProductNoBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}