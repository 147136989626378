import { ServerFetch } from '../main-model'

export default class InvoiceSupplierListModel extends ServerFetch {
    getInvoiceSupplierListBy = (data) => this.authFetch({
        url: 'invoice-supplier-list/getInvoiceSupplierListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateInvoiceSupplierListBy = (data) => this.authFetch({
        url: 'invoice-supplier-list/generateInvoiceSupplierListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateBarcodeStatusBy = (data) => this.authFetch({
        url: 'invoice-supplier-list/updateBarcodeStatusBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceSupplierListLatestSupplier = (data) => this.authFetch({
        url: 'invoice-supplier-list/getInvoiceSupplierListLatestSupplier',
        method: 'POST',
        body: JSON.stringify(data),
    })
}