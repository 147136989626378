import { ServerFetch } from '../main-model'

export default class ProductSparePartModel extends ServerFetch {

    async getProductSparePartBy(data) {
        return this.authFetch({
            url: 'product-spare-part/getProductSparePartBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
}