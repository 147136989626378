import { ServerFetch } from '../main-model'

export default class SaleCloseShiftModel extends ServerFetch {
    getSaleCloseShiftBy = (data) => this.authFetch({
        url: 'sale-close-shift/getSaleCloseShiftBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSaleCloseShiftByCode = (data) => this.authFetch({
        url: 'sale-close-shift/getSaleCloseShiftByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseApproveSaleCloseShift = (data) => this.authFetch({
        url: 'sale-close-shift/getAdviseApproveSaleCloseShift',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateSaleCloseShiftBy = (data) => this.authFetch({
        url: 'sale-close-shift/generateSaleCloseShiftBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}