import { ServerFetch } from '../main-model'

export default class InvoiceCustomerTempListModel extends ServerFetch {
    getInvoiceCustomerTempListBy = (data) => this.authFetch({
        url: 'invoice-customer-temp-list/getInvoiceCustomerTempListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateInvoiceCustomerTempListBy = (data) => this.authFetch({
        url: 'invoice-customer-temp-list/generateInvoiceCustomerTempListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAverageInvoiceCustomerTempListBy = (data) => this.authFetch({
        url: 'invoice-customer-temp-list/getAverageInvoiceCustomerTempListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getIncomeSaleBy = (data) => this.authFetch({
        url: 'invoice-customer-temp-list/getIncomeSaleBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getIncomeCustomerBy = (data) => this.authFetch({
        url: 'invoice-customer-temp-list/getIncomeCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getIncomeProductBy = (data) => this.authFetch({
        url: 'invoice-customer-temp-list/getIncomeProductBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}