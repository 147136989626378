import { ServerFetch } from '../main-model'

export default class CustomerCarTypeModel extends ServerFetch {
    getCustomerCarTypeBy = (data) => this.authFetch({
        url: 'customer-car-type/getCustomerCarTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCustomerCarTypeByCode = (data) => this.authFetch({
        url: 'customer-car-type/getCustomerCarTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}