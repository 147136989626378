import { ServerFetch } from '../main-model'

export default class CustomerModel extends ServerFetch {
    generateCustomerLastCode = (data) => this.authFetch({
        url: 'customer/generateCustomerLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCustomerBy = (data) => this.authFetch({
        url: 'customer/getCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCustomerByCode = (data) => this.authFetch({
        url: 'customer/getCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    checkUsernameBy = (data) => this.authFetch({
        url: 'customer/checkUsernameBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateCustomerBy = (data) => this.authFetch({
        url: 'customer/updateCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertCustomer = (data) => this.authFetch({
        url: 'customer/insertCustomer',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteCustomerByCode = (data) => this.authFetch({
        url: 'customer/deleteCustomerByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}