import { ServerFetch } from '../main-model'

export default class AccountSettingModel extends ServerFetch {
    getAccountSettingBy = (data) => this.authFetch({
        url: 'account-setting/getAccountSettingBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAccountSettingByCode = (data) => this.authFetch({
        url: 'account-setting/getAccountSettingByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateAccountSettingBy = (data) => this.authFetch({
        url: 'account-setting/updateAccountSettingBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}