import { ServerFetch } from '../main-model'

export default class DebitMethodTypeModel extends ServerFetch {
    getDebitMethodTypeBy = (data) => this.authFetch({
        url: 'debit-method-type/getDebitMethodTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitMethodTypeByCode = (data) => this.authFetch({
        url: 'debit-method-type/getDebitMethodTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}