import { ServerFetch } from '../main-model'

export default class AssetTypeModel extends ServerFetch {
    
    async getLastCodeBy(data) {
        return this.authFetch({
            url: 'generate-format/get-last-code-by',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
    async getAssetTypeBy(data) {
        return this.authFetch({
            url: 'asset-type/getAssetTypeBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getAssetTypeByCode(data) {
        return this.authFetch({
            url: 'asset-type/getAssetTypeByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async updateAssetTypeBy(data) {
        return this.authFetch({
            url: 'asset-type/updateAssetType',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async insertAssetType(data) {
        return this.authFetch({
            url: 'asset-type/insertAssetType',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async deleteAssetTypeByCode(data) {
        return this.authFetch({
            url: 'asset-type/deleteAssetType',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
    
}