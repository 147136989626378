import { ServerFetch } from '../main-model'

export default class ReceiptPaymentListModel extends ServerFetch {
    getReceiptPaymentListBy = (data) => this.authFetch({
        url: 'receipt-payment-list/getReceiptPaymentListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptPaymentListByCode = (data) => this.authFetch({
        url: 'receipt-payment-list/getReceiptPaymentListByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseCheckReceiptPaymentList = (data) => this.authFetch({
        url: 'receipt-payment-list/getAdviseCheckReceiptPaymentList',
        method: 'POST',
        body: JSON.stringify(data),
    })

    approveReceiptPaymentListByCode = (data) => this.authFetch({
        url: 'receipt-payment-list/approveReceiptPaymentListByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    disapproveReceiptPaymentListByCode = (data) => this.authFetch({
        url: 'receipt-payment-list/disapproveReceiptPaymentListByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}