import { ServerFetch } from '../main-model'

export default class StockUnpackModel extends ServerFetch {
    generateStockUnpackLastCode = (data) => this.authFetch({
        url: 'stock-unpack/generateStockUnpackLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockUnpackBy = (data) => this.authFetch({
        url: 'stock-unpack/getStockUnpackBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockUnpackByCode = (data) => this.authFetch({
        url: 'stock-unpack/getStockUnpackByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateStockUnpackBy = (data) => this.authFetch({
        url: 'stock-unpack/updateStockUnpackBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    createStock = (data) => this.authFetch({
        url: 'stock-unpack/createStock',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertStockUnpack = (data) => this.authFetch({
        url: 'stock-unpack/insertStockUnpack',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteStockUnpackByCode = (data) => this.authFetch({
        url: 'stock-unpack/deleteStockUnpackByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}