import { ServerFetch } from '../main-model'

export default class CustomerTypeModel extends ServerFetch {
    generateCustomerTypeLastCode = (data) => this.authFetch({
        url: 'customer-type/generateCustomerTypeLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCustomerTypeBy = (data) => this.authFetch({
        url: 'customer-type/getCustomerTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCustomerTypeByCode = (data) => this.authFetch({
        url: 'customer-type/getCustomerTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateCustomerTypeBy = (data) => this.authFetch({
        url: 'customer-type/updateCustomerTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertCustomerType = (data) => this.authFetch({
        url: 'customer-type/insertCustomerType',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteCustomerTypeByCode = (data) => this.authFetch({
        url: 'customer-type/deleteCustomerTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}