import { ServerFetch } from '../main-model'

export default class ProductUnitModel extends ServerFetch {
    async getProductUnitLastCode(data) {
        return this.authFetch({
            url: 'product-unit/getProductUnitLastCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getProductUnitBy(data) {
        return this.authFetch({
            url: 'product-unit/getProductUnitBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async getProductUnitByCode(data) {
        return this.authFetch({
            url: 'product-unit/getProductUnitByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async insertProductUnit(data) {
        return this.authFetch({
            url: 'product-unit/insertProductUnit',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async updateProductUnitByCode(data) {
        return this.authFetch({
            url: 'product-unit/updateProductUnitByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }

    async deleteProductUnitByCode(data) {
        return this.authFetch({
            url: 'product-unit/deleteProductUnitByCode',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
}