import { ServerFetch } from '../main-model'

export default class ProductSetModel extends ServerFetch {

    async getProductSetBy(data) {
        return this.authFetch({
            url: 'product-set/getProductSetBy',
            method: 'POST',
            body: JSON.stringify(data),
        })
    }
}