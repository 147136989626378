import { ServerFetch } from '../main-model'

export default class CreditNoteCustomerListModel extends ServerFetch {
    getCreditNoteCustomerListBy = (data) => this.authFetch({
        url: 'credit-note-customer-list/getCreditNoteCustomerListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateCreditNoteCustomerListBy = (data) => this.authFetch({
        url: 'credit-note-customer-list/generateCreditNoteCustomerListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}