import { ServerFetch } from '../main-model'

export default class ProductModel extends ServerFetch {
    getProductLastCode = (data) => this.authFetch({
        url: 'product/getProductLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductBy = (data) => this.authFetch({
        url: 'product/getProductBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductHaveSet = (data) => this.authFetch({
        url: 'product/getProductHaveSet',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductByCode = (data) => this.authFetch({
        url: 'product/getProductByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductBySpec = (data) => this.authFetch({
        url: 'product/getProductBySpec',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateProductBy = (data) => this.authFetch({
        url: 'product/updateProductBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertProduct = (data) => this.authFetch({
        url: 'product/insertProduct',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteProductByCode = (data) => this.authFetch({
        url: 'product/deleteProductByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}