import { ServerFetch } from '../main-model'

export default class SaleOrderModel extends ServerFetch {
    getSaleOrderLastCode = (data) => this.authFetch({
        url: 'sale-order/getSaleOrderLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSaleOrderBy = (data) => this.authFetch({
        url: 'sale-order/getSaleOrderBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSaleOrderByCode = (data) => this.authFetch({
        url: 'sale-order/getSaleOrderByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateSaleOrderBy = (data) => this.authFetch({
        url: 'sale-order/updateSaleOrderBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertSaleOrder = (data) => this.authFetch({
        url: 'sale-order/insertSaleOrder',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteSaleOrderByCode = (data) => this.authFetch({
        url: 'sale-order/deleteSaleOrderByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}