import { ServerFetch } from '../main-model'

export default class NotificationModel extends ServerFetch {
    getNotificationBy = (data) => this.authFetch({
        url: 'notification/getNotificationBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertNotificationBy = (data) => this.authFetch({
        url: 'notification/insertNotification',
        method: 'POST',
        body: JSON.stringify(data),
    })
    updateAllNotificationBy = (data) => this.authFetch({
        url: 'notification/updateAllNotificationBy',
        method: 'POST',
        body: JSON.stringify(data),
    }) 
    updateNotificationByCode = (data) => this.authFetch({
        url: 'notification/updateNotificationByCode',
        method: 'POST',
        body: JSON.stringify(data),
    }) 
    deleteNotification = (data) => this.authFetch({
        url: 'notification/deleteNotification',
        method: 'POST',
        body: JSON.stringify(data),
    })

}