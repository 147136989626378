import React, { Component } from 'react'
import Swal from 'sweetalert2'
import jwt_decode from "jwt-decode"

import { AuthProvider } from '../../role-accress/authContext'

import Authoring from './Authoring'

import GLOBAL from '../../GLOBAL'

import { CompanyInfoModel, UserModel, } from '../../models'

const user_model = new UserModel()
const company_model = new CompanyInfoModel()

class Auth extends Component {
  state = {
    loading: true,
    authcertifying: true,
    authenticated: false,
    permissions: [],
    company_info: {},
    user: {},
  }

  componentDidMount() {
    this._initiateAuthentication()
  }

  _handleLogin = (data) => {
    if (this.state.loading === false) {
      this.setState({ loading: true, }, async () => {
        const login_result = await user_model.checkLogin({
          user_username: data.user_username,
          user_password: data.user_password,
        })
        const company_info = await company_model.getCompanyInfo()

        if (login_result.require === false) {
          this.setState({
            loading: false,
            authcertifying: false,
          }, () => {
            Swal.fire({ title: "ไม่สามารถล็อคอินได้ !", text: 'คำขอเกิดข้อผิดพลาด', icon: "error", })
          })
        } else if (login_result.data.length === 0) {
          this.setState({
            loading: false,
            authcertifying: false,
          }, () => {
            Swal.fire({ title: "ไม่สามารถล็อคอินได้ !", text: 'โปรดตรวจสอบชื่อผู้ใช้และรหัสผ่านของคุณ', icon: "warning", })
          })
        } else {
          this._setSession({
            x_access_token: login_result.x_access_token,
            permissions_token: login_result.permissions_token,
            company_info: company_info.data[0],
            user: login_result.data[0],
          })
        }
      })
    }
  }

  _initiateAuthentication = async () => {
    try {
      const serialized = localStorage.getItem('session-user')

      if (serialized !== null) {
        const login_token = JSON.parse(serialized)

        const login_result = await user_model.checkLogin({
          user_username: login_token.user_username,
          user_password: login_token.user_password,
        })
        const company_info = await company_model.getCompanyInfo()

        this.setState({
          loading: false,
          authcertifying: false,
        }, () => {
          if (login_result.require && login_result.data.length) {
            this._setSession({
              x_access_token: login_result.x_access_token,
              permissions_token: login_result.permissions_token,
              user: login_result.data[0],
              company_info: company_info.data[0],
            })
          }
        })
      } else {
        this.setState({
          loading: false,
          authcertifying: false,
        })
      }
    } catch (e) {
      console.log('_initiateAuthentication ', e)
    }
  }

  _setSession(session) {
    try {
      localStorage.setItem('x-access-token', session.x_access_token)
      localStorage.setItem('session-user', JSON.stringify(session.user))

      GLOBAL.ACCESS_TOKEN = { 'x-access-token': session.x_access_token }

      const { permissions } = jwt_decode(session.permissions_token)

      this.setState({
        loading: false,
        authcertifying: false,
        authenticated: true,
        permissions: permissions || [],
        user: session.user,
        company_info: session.company_info,
      })
    } catch (e) {
      console.log('_setSession ', e)
    }
  }

  _handleLogout = () => {
    try {
      localStorage.clear()
      window.location.reload()
    } catch (e) {
      console.log('_handleLogout ', e)
    }
  }

  render() {
    return (
      <AuthProvider
        value={{
          ...this.state,
          _handleLogin: this._handleLogin,
          _initiateAuthentication: this._initiateAuthentication,
          _handleLogout: this._handleLogout
        }}
      >
        {this.state.authcertifying ? <Authoring /> : this.props.children}
      </AuthProvider>
    )
  }
}

export default Auth