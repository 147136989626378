import GLOBAL from '../GLOBAL'

class MainModel {
    async directEndpointFetch(endpoint, data) {
        const response = await fetch(endpoint, {
            method: data.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: data.body,
        }).then((response) => response.json().then((responseJson) => {
            return responseJson
        })).catch((error) => {
            return { require: false, data: [], error, }
        })

        return response
    }

    async authEndpointFetch(endpoint, data) {
        const response = await fetch(endpoint, {
            method: data.method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                ...GLOBAL.ACCESS_TOKEN,
            },
            body: data.body,
        }).then((response) => response.json().then((responseJson) => {
            return responseJson
        })).catch((error) => {
            return { require: false, data: [], error, }
        })

        if (response.unauthorized) {
            console.log('unauthorized', response.error)

            localStorage.clear()
            window.location.reload()
        }

        return response
    }
}

export class ServerFetch extends MainModel {
    directFetch = (data) => this.directEndpointFetch(`${GLOBAL.SERVER.URL}${data.url}`, data)
    authFetch = (data) => this.authEndpointFetch(`${GLOBAL.SERVER.URL}${data.url}`, data)
}