import { ServerFetch } from '../main-model'

export default class ProductSupplierModel extends ServerFetch {
    getProductSupplierBy = (data) => this.authFetch({
        url: 'product-supplier/getProductSupplierBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductSupplierByCode = (data) => this.authFetch({
        url: 'product-supplier/getProductSupplierByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}